import {
    GP_CAPTCHA_SITE_KEY,
    GlobalStyles,
    LanguageContextProvider,
    Suspender,
    ThemeProvider,
    theme,
} from '@gopay/core';
import { StrictMode, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const App = lazy(() =>
    import('App').then((module) => ({
        default: module.App,
    })),
);

createRoot(document.getElementById('app') as HTMLElement).render(
    <StrictMode>
        <GoogleReCaptchaProvider reCaptchaKey={GP_CAPTCHA_SITE_KEY}>
            <LanguageContextProvider>
                <ThemeProvider theme={theme}>
                    <GlobalStyles />
                    <Suspender element={<App />} />
                </ThemeProvider>
            </LanguageContextProvider>
        </GoogleReCaptchaProvider>
    </StrictMode>,
);
